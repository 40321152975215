import './css/Style.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";

import "popper.js/dist/popper.min.js"
import "bootstrap/dist/js/bootstrap.min.js"

import { useRef } from "react";
import { Form, Button } from "react-bootstrap";

function Home()  {

    const RefEmpEmail = useRef(null);
    const RefClientEmail = useRef(null);

    const EmpPortalSubmit = (e) => {
        e.preventDefault();

        var domain = RefEmpEmail.current.value.split("@")[1].split(".")[0];
        
        if (domain == "suncor"){
            window.location.href ='http://tsum.suncor.inrush.inc/'
        }else if(domain == "shell"){
            window.location.href ='http://tsum.shell.inrush.inc/'
        }else if(domain == "hydro"){
            window.location.href ='http://tsum.hydro.inrush.inc/'
        }else {
            alert("Please enter a valid email address");
        }

    }

    const ClientPortalSubmit = (e) => {
        e.preventDefault();

        var domain = RefClientEmail.current.value.split("@")[1].split(".")[0];
        
        if (domain == "suncor"){
            window.location.href ='http://tsum.suncor.inrush.inc/'
        }else if(domain == "shell"){
            window.location.href ='http://tsum.shell.inrush.inc/'
        }else if(domain == "hydro"){
            window.location.href ='http://tsum.hydro.inrush.inc/'
        }else {
            alert("Please enter a valid email address");
        }
    }

    return (
        <body>
            <div className="ProjectD">
                <nav className="navbar navbar-expand-lg py-3">
                    <div className="container-fluid px-md-5">
                    <a className="navbar-brand" href="#">
                        <img src="./logo12.png" className="img-fluid" srcset=""/>
                    </a>
                    <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Industries</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Solutions</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Training</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-r" href="#">Contact us</a>
                                </li>
                            </ul>
                            <div className="button">
                                <button className="btn btn-login ms-lg-4">Login</button>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="content">
                    <div className="row gx-lg-0">
                        <div className="col-md-5">
                            <div className="c-left d-flex flex-column justify-content-cennter align-items-center">
                                <div className="text">
                                        <h3 className="">inrushelectrical.com</h3>
                                        <p className="">"We are a strong & dedicated group of professionals with a diverse skillset and experience in high voltage electrical engineering and field services. Primarily centered around providing consultancy and training, the core business focuses on power system studies, protection & control, SCADA, and automation support."</p>
                                </div>
                                <div className="text2">
                                        <p className="d-md-block d-none">Copyright © 2024 Inrush Electrical Technology Inc.
                                            <br/>All rights reserved</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="c-right offset-lg-2 d-flex justify-content-cennter align-items-center">
                                <div className="box mb-5">
                                    <h3>Login Portal</h3>
                                    <div className="right-content">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-home" type="button" role="tab"
                                                    aria-controls="pills-home" aria-selected="true"><span>
                                                        <i className="bi bi-person-fill mx-2"></i></span>Employee</button>
                                            </li>
                                            <li className="nav-item mx-3" role="presentation">
                                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-profile" type="button" role="tab"
                                                    aria-controls="pills-profile" aria-selected="false"><span>
                                                    <i className="bi bi-people-fill mx-2"></i></span>Client</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                aria-labelledby="pills-home-tab" tabindex="0">
                                                <div className="mb-3">
                                                <form onSubmit={EmpPortalSubmit} >
                                                    <Form.Group controlId="EmpEmail">
                                                       <Form.Control className="form-control" type="email" name="EmpEmail" ref={RefEmpEmail}  placeholder="Email Address" required title='Please enter the Email'/>
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Button variant="primary" type="submit" className="btn btn-continue mt-3">Continue</Button>
                                                    </Form.Group>
                                                </form>    
                                                </div>
                                            </div>
                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                        <div className="mb-3">
											<form onSubmit={ClientPortalSubmit} >
                                                    <Form.Group controlId="Clientportalgroup">
                                                       <Form.Control className="form-control" type="email" name="ClientEmail" ref={RefClientEmail} placeholder="Email Address" required title='Please enter the Email'/>
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <Button variant="primary" type="submit" className="btn btn-continue mt-3">Continue</Button>
                                                    </Form.Group>
                                            </form>    
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </body>
    )
};

export default Home;